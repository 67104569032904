.DashboardPage {
  height: 100vh;
  .ant-layout-sider {
    background: #fff;
    padding-top: 15px;
  }
  .ant-layout-header {
    background-color: #223670;
    @media screen and (max-width: 768px) {
      padding: 0 10px;
      .ant-select-selector {
        width: 80px;
        .ant-select-selection-item {
          padding-right: 18px !important;
        }
      }
    }
    .profile-menu {
      float: right;
      .ant-dropdown-trigger {
        cursor: pointer;
      }
      .username {
        display: inline-block;
        color: #fff;
      }
      .anticon {
        margin: 0 7.5px;
        font-size: 18px;
        vertical-align: middle;
        transition: all 0.3s ease;
        opacity: 0.8;
        color: #efefef;
        @media screen and (max-width: 768px) {
          margin: 0 2.5px;
        }
        &:first-of-type {
          margin-left: 15px;
          @media screen and (max-width: 768px) {
            margin-left: 7.5px;
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
        &:hover,
        &.selected {
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
}
