.FamilyRankingTable {
  background: #fff;
  .filter-control {
    margin: 10px 0;
    .ant-select {
      margin-right: 15px;
    }
  }
  .ant-table-row {
    cursor: pointer;
  }
}