.FormModal {
  .ant-form-item:last-of-type {
    text-align: center;
    margin-bottom: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 104px;
    height: auto;
    min-height: 104px;
    margin-right: 0;
    margin-bottom: 0;
    .ant-upload {
      min-height: 102px;
      width: 100%;
      .img-upload-btn {
        margin-top: 8px;
      }
      .img-remove-btn {
        color: #d00;
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 999;
        cursor: pointer;
        &:hover {
          color: #f00;
        }
      }
    }
  }
}
