.RankingPage {
  padding: 15px;
  .main-section {
    background: #fff;
    padding: 15px;
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}
