.AdminPage {
  padding: 15px;
  .main-section {
    background: #fff;
    padding: 15px;
    .banner-image {
      max-width: 250px;
    }
  }
}
