.LoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .LoginPage-container {
    width: 100%;
    .LoginPage-logo-container {
      text-align: center;
      margin-bottom: 10px;
      .LoginPage-logo {
        max-width: 200px;
        margin: 0px auto;
      }
      .main-title {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 0;
        font-weight: bold;
      }
      .side-title {
        font-size: 14px;
        margin-bottom: 0;
        color: #999;
      }
    }
    .LoginPage-form-container {
      max-width: 300px;
      margin: 0 auto;
      .error-message {
        height: 20px;
        margin-bottom: 0;
        color: #f5222d;
      }
      .LoginPage-form {
        .ant-form-item {
          text-align: center;
          &:first-of-type {
            margin-bottom: 5px;
          }
        }
        .login-form-forgot {
          float: right;
        }
        .login-form-button {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
}
