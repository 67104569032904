.TableButtonGroup {
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  .table-btn {
    display: inline-block;
    font-size: 18px;
    padding: 5px 20px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 5px 7.5px;
    }
    .anticon {
      font-size: 16px;
      padding: 2px;
      color: #fff;
      margin-right: 2px;
      @media screen and (max-width: 768px) {
        margin-right: 1px;
      }
    }
    &:hover {
      background: #f2f2f2;
    }
    &.create-btn {
      .anticon {
        background: #223670;
      }
    }
    &.approve-btn {
      .anticon {
        background: #26af19;
      }
    }
    &.delete-btn {
      .anticon {
        background: #f11;
      }
    }
  }
}
