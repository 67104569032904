.PostPage {
  padding: 15px;
  .main-section {
    background: #fff;
    padding: 15px;
    .banner-image {
      max-width: 100%;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}
