.PostSharePage {
  background: #000;
  display: flex;
  align-items: center;
  height: 100vh;
  padding-top: 15px;
  flex-direction: column;
  img {
    width: 100%;
  }
  .error-message {
    color: #fff;
  }
  .post-container {
    color: #fff;
    min-width: 300px;
    max-width: 350px;
    border: 1px solid #999;
    border-radius: 5px;
    padding: 5px 10px;
    .post-type {
      font-weight: bold;
    }
    .image-comparision {
      display: flex;
      .comparision-block {
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
    .user-profile {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .username {
        display: inline-block;
        font-weight: bold;
        margin-left: 5px;
      }
    }
    .post-content-container {
      margin: 10px 0;
      video {
        max-height: 450px;
      }
      .post-text {
        font-size: 16px;
      }
    }
  }
  .badge {
    width: 150px;
  }
  .apple {
    padding: 10px 20px;
  }
  .additional-text {
    text-align: center;
    max-width: 350px;
    margin-top: 10px;
    color: #fff;
  }
}
