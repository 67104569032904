.EmailVerificationPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  img {
    max-width: 500px;
  }
  p {
    font-size: 24px;
  }
}
