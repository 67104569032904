.VideoPlayerModal {
  max-height: 500px;
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    background-color: #000;
    .ant-modal-close {
      color: #ccc;
      &:hover {
        color: #fff;
      }
      .ant-modal-close-x {
        font-size: 24px;
      }
    }
  }
}
