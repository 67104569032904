.TaskScheduleInput {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  th,
  td {
    width: 15%;
    &:first-of-type {
      width: 10%;
    }
  }
}
